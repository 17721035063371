import {
  Box,
  Button,
  TextField,
  useTheme,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  Checkbox,
  FormControlLabel,
  TableContainer,
  Paper,
  InputAdornment,
} from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { Switch } from "@mui/material"; // Import the Switch component
import Header from "../../components/Header";

const CreateRoute = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [routes, setRoutes] = useState(initialRoutes);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState({});
  const [locations] = useState(initialLocations);
  const [searchTerm, setSearchTerm] = useState("");

  const handleFormSubmit = (values, { resetForm }) => {
    setRoutes([...routes, { ...values, id: routes.length + 1, locations: {} }]); // Initialize locations as empty
    resetForm();
  };

  const handleDelete = (id) => {
    setRoutes(routes.filter((route) => route.id !== id));
  };

  const handleAssign = (route) => {
    setSelectedRoute(route);
    setOpenAssignModal(true);
  };

  const handleView = (route) => {
    setSelectedRoute(route);
    setOpenViewModal(true);
  };

  
  const handleSaveLocations = () => {
    const updatedLocations = Object.keys(selectedLocations).filter(id => selectedLocations[id]);
    const updatedRoute = {
      ...selectedRoute,
      locations: updatedLocations.length > 0 ? updatedLocations.reduce((acc, curr) => {
        acc[curr] = true; // Store as an object with IDs as keys
        return acc;
      }, {}) : {}
    };
    
    const updatedRoutes = routes.map((route) =>
      route.id === selectedRoute.id ? updatedRoute : route
    );

    setRoutes(updatedRoutes);
    setSelectedRoute(updatedRoute); // Update selectedRoute with selected locations
    setOpenAssignModal(false);
  };

 // Separate state for toggle reporting
const [reportingStatus, setReportingStatus] = useState({});

const handleLocationChange = (locationId) => {
  setSelectedLocations((prev) => ({
    ...prev,
    [locationId]: !prev[locationId], // Toggle the location selection
  }));
};

// New handler for the switch toggle
const handleReportingChange = (locationId) => {
  setReportingStatus((prev) => ({
    ...prev,
    [locationId]: !prev[locationId], // Toggle the reporting status
  }));
};


  const filteredLocations = locations.filter((location) =>
    location.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    location.pincode.includes(searchTerm)
  );

  
  return (
    <Box m="20px">
      <Header title="Create Route" subtitle="Enter the New Route Details" />
      <Card>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={routeSchema}
            onSubmit={handleFormSubmit}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box mt="16px" display="flex" flexDirection="column">
                  <Box display="flex" gap={2}>
                    <TextField
                      sx={{ flex: 1 }}
                      id="routeCode"
                      label="Route Code"
                      value={values.routeCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.routeCode && Boolean(errors.routeCode)}
                      helperText={touched.routeCode && errors.routeCode}
                      margin="normal"
                    />
                    <TextField
                      sx={{ flex: 1 }}
                      id="routeName"
                      label="Route Name"
                      value={values.routeName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.routeName && Boolean(errors.routeName)}
                      helperText={touched.routeName && errors.routeName}
                      margin="normal"
                    />
                    <TextField
                      sx={{ flex: 1 }}
                      id="routeShortName"
                      label="Route Short Name"
                      value={values.routeShortName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.routeShortName && Boolean(errors.routeShortName)}
                      helperText={touched.routeShortName && errors.routeShortName}
                      margin="normal"
                    />
                  </Box>
                  <Box display="flex" gap={2}>
                    <TextField
                      sx={{ flex: 1 }}
                      id="startDate"
                      label="Start Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.startDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.startDate && Boolean(errors.startDate)}
                      helperText={touched.startDate && errors.startDate}
                      margin="normal"
                    />
                    <TextField
                      sx={{ flex: 1 }}
                      id="endDate"
                      label="End Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.endDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.endDate && Boolean(errors.endDate)}
                      helperText={touched.endDate && errors.endDate}
                      margin="normal"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.temporary}
                          onChange={handleChange}
                          name="temporary"
                        />
                      }
                      label="Temporary (Custom Route)"
                    />
                  </Box>
                </Box>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    mt: 2,
                  }}
                >
                  Add Route
                </Button>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>

      <Box mt="20px" style={{ height: "300px", overflow: "auto" }}>
        <TableContainer component={Paper}>
          <Table style={{ backgroundColor: "white" }}>
            <TableHead>
              <TableRow>
                <TableCell>Sl.No.</TableCell>
                <TableCell>Route Code</TableCell>
                <TableCell>Route Name</TableCell>
                <TableCell>Short Name</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Temporary</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {routes.map((route, index) => (
                <TableRow key={route.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{route.routeCode}</TableCell>
                  <TableCell>{route.routeName}</TableCell>
                  <TableCell>{route.routeShortName}</TableCell>
                  <TableCell>{new Date(route.startDate).toLocaleDateString()}</TableCell>
                  <TableCell>{new Date(route.endDate).toLocaleDateString()}</TableCell>
                  <TableCell>{route.temporary ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleAssign(route)}
                      color="primary"
                      variant="outlined"
                      sx={{ mr: 1 }}
                    >
                      Assign
                    </Button>
                    <Button
                      onClick={() => handleView(route)}
                      color="primary"
                      variant="outlined"
                      sx={{ mr: 1 }}
                    >
                      View
                    </Button>
                    <IconButton onClick={() => handleDelete(route.id)} color="secondary">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Modal for assigning locations */}
      {/* Modal for assigning locations */}
<Modal open={openAssignModal} onClose={() => setOpenAssignModal(false)}>
  <Box
    sx={{
      width: '80%',
      maxWidth: '600px',
      margin: '100px auto',
      padding: '20px',
      backgroundColor: 'white',
      borderRadius: '8px',
    }}
  >
    <h3>Assign Locations</h3>
    <TextField
      label="Search"
      variant="outlined"
      fullWidth
      margin="normal"
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
    <TableContainer sx={{ maxHeight: '300px', overflow: 'auto' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Select</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Pincode</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Reporting</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
  {filteredLocations.map((location) => (
    <TableRow key={location.id}>
      <TableCell>
        <Checkbox
          checked={selectedLocations[location.id] || false}
          onChange={() => handleLocationChange(location.id)}
          disabled={!location.isEnabled} // Disable checkbox based on status
        />
      </TableCell>
      <TableCell>{location.name}</TableCell>
      <TableCell>{location.pincode}</TableCell>
      <TableCell>{location.isEnabled ? "Enabled" : "Disabled"}</TableCell>
      <TableCell>
        <Switch
          checked={reportingStatus[location.id] || false} // Manage state for Reporting toggle
          onChange={() => handleReportingChange(location.id)}
          disabled={!location.isEnabled} // Disable toggle based on status
        />
      </TableCell>
    </TableRow>
  ))}
</TableBody>
      </Table>
    </TableContainer>
    <Button onClick={handleSaveLocations} variant="contained" sx={{ mt: 2 }}>
      Save
    </Button>
    <Button onClick={() => setOpenAssignModal(false)} variant="outlined" sx={{ mt: 2, ml: 1 }}>
      Cancel
    </Button>
  </Box>
</Modal>



      {/* Modal for viewing route details */}
      <Modal open={openViewModal} onClose={() => setOpenViewModal(false)}>
        <Box
          sx={{
            width: '80%',
            maxWidth: '600px',
            margin: '100px auto',
            padding: '20px',
            backgroundColor: 'white',
            borderRadius: '8px',
          }}
        >
          <h3>Route Details</h3>
          <p><strong>Route Name:</strong> {selectedRoute?.routeName}</p>
          <p><strong>Assigned Locations:</strong>Hampankatta, Kottara, Kadri, Bjai</p>
          <Button onClick={() => setOpenViewModal(false)} variant="contained" sx={{ mt: 2 }}>Close</Button>
        </Box>
      </Modal>
    </Box>
  );
};

// Initial values for the form
const initialValues = {
  routeCode: "",
  routeName: "",
  routeShortName: "",
  startDate: "",
  endDate: "",
  temporary: false,
};

// Validation schema
const routeSchema = yup.object().shape({
  routeCode: yup.string().required("Route Code is required"),
  routeName: yup.string().required("Route Name is required"),
  routeShortName: yup.string().required("Route Short Name is required"),
  startDate: yup.date().required("Start Date is required"),
  endDate: yup.date().required("End Date is required"),
});



// Sample routes
const initialRoutes = [
  {
    id: 1,
    routeCode: "R001",
    routeName: "Karangalpady to Balmatta",
    routeShortName: "KRBM",
    startDate: new Date("2024-01-01"),
    endDate: new Date("2024-12-31"),
    temporary: false,
  },
  {
    id: 2,
    routeCode: "R002",
    routeName: "Bejai to Lalbagh",
    routeShortName: "BJLBG",
    startDate: new Date("2024-02-15"),
    endDate: new Date("2024-08-15"),
    temporary: true,
  },
];

  
  // Pre-defined locations for selection
  // Pre-defined locations for selection
const initialLocations = [
  { id: 1, name: "Hampankatta", pincode: "575001", isEnabled: true },
  { id: 2, name: "Kottara", pincode: "575006", isEnabled: true },
  { id: 3, name: "Kadri", pincode: "575002", isEnabled: false }, // Example of a disabled location
  { id: 4, name: "Bejai", pincode: "575004", isEnabled: true },
  { id: 5, name: "Falnir", pincode: "575002", isEnabled: true },
  { id: 6, name: "Balmatta", pincode: "575003", isEnabled: false },
  { id: 7, name: "Kankanady", pincode: "575003", isEnabled: true },
  { id: 8, name: "Bendoor", pincode: "575002", isEnabled: true },
  { id: 9, name: "Surathkal", pincode: "575014", isEnabled: true },
  { id: 10, name: "Kodialbail", pincode: "575003", isEnabled: true },
  { id: 11, name: "Bikarnakatte", pincode: "575005", isEnabled: true },
  { id: 12, name: "Kulshekar", pincode: "575010", isEnabled: true },
];

  
  export default CreateRoute;
  