import { tokens } from "../theme";

export const mockDataTeam = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
];

export const mockDataCustomers = [
  {
    id: 1,
    companyName: "Mangalore Bakes",
    ownerName: "Rajesh Shetty",
    email: "rajesh.shetty@gmail.com",
    phone: "9876543210",
    address: "1st Floor, AJ Complex, Mangalore",
    pincode: "575003",
    location: "Karangalpady",
    route: "Karangalpady to Balmatta",
  },
  {
    id: 2,
    companyName: "Coastal Foods",
    ownerName: "Vinod Pai",
    email: "vinod.pai@gmail.com",
    phone: "9876543211",
    address: "Shop No 209, City Center, Mangalore",
    pincode: "575004",
    location: "Bejai",
    route: "Bejai to Bendoor",
  },
  {
    id: 3,
    companyName: "Udupi Sweets",
    ownerName: "Suresh Kamath",
    email: "suresh.kamath@gmail.com",
    phone: "9876543212",
    address: "2nd Floor, Empire Mall, Mangalore",
    pincode: "575002",
    location: "Kankanady",
    route: "Kankanady to Kadri",
  },
  {
    id: 4,
    companyName: "Oceanic Exports",
    ownerName: "Nithin Shenoy",
    email: "nithin.shenoy@gmail.com",
    phone: "9876543213",
    address: "Shop No 5, Highland Plaza, Mangalore",
    pincode: "575002",
    location: "Bendoor",
    route: "Bendoor to Falnir",
  },
  {
    id: 5,
    companyName: "Golden Caterers",
    ownerName: "Lakshmi Rao",
    email: "lakshmi.rao@gmail.com",
    phone: "9876543214",
    address: "Ground Floor, Prestige Building, Mangalore",
    pincode: "575004",
    location: "Kadri",
    route: "Kadri to Bejai",
  },
  {
    id: 6,
    companyName: "Classic Furnitures",
    ownerName: "Anil Prabhu",
    email: "anil.prabhu@gmail.com",
    phone: "9876543215",
    address: "3rd Floor, Forum Fiza Mall, Mangalore",
    pincode: "575001",
    location: "Hampankatta",
    route: "Hampankatta to Kodialbail",
  },
  {
    id: 7,
    companyName: "City Electricals",
    ownerName: "Geetha Acharya",
    email: "geetha.acharya@gmail.com",
    phone: "9876543216",
    address: "Shop No 12, Bharat Mall, Mangalore",
    pincode: "575002",
    location: "Balmatta",
    route: "Balmatta to Kadri",
  },
  {
    id: 8,
    companyName: "Reliable Traders",
    ownerName: "Harish Nayak",
    email: "harish.nayak@gmail.com",
    phone: "9876543217",
    address: "1st Floor, Unity Building, Mangalore",
    pincode: "575001",
    location: "Falnir",
    route: "Falnir to Kankanady",
  },
  {
    id: 9,
    companyName: "Shree Jewellers",
    ownerName: "Priya Shenoy",
    email: "priya.shenoy@gmail.com",
    phone: "9876543218",
    address: "Shop No 8, Gold Souk, Mangalore",
    pincode: "575001",
    location: "Pandeshwar",
    route: "Pandeshwar to Hampankatta",
  },
  {
    id: 10,
    companyName: "Rainbow Fashions",
    ownerName: "Rohit Rai",
    email: "rohit.rai@gmail.com",
    phone: "9876543219",
    address: "2nd Floor, City Plaza, Mangalore",
    pincode: "575005",
    location: "Bikarnakatte",
    route: "Bikarnakatte to Kulshekar",
  },
  {
    id: 11,
    companyName: "Ocean Fresh Fish",
    ownerName: "Sandhya Kumar",
    email: "sandhya.kumar@gmail.com",
    phone: "9876543220",
    address: "Ground Floor, Fish Market, Mangalore",
    pincode: "575006",
    location: "Kottara",
    route: "Kottara to Surathkal",
  },
  {
    id: 12,
    companyName: "Bright Interiors",
    ownerName: "Nagesh Kini",
    email: "nagesh.kini@gmail.com",
    phone: "9876543221",
    address: "Shop No 15, Interior Mall, Mangalore",
    pincode: "575014",
    location: "Surathkal",
    route: "Surathkal to Panambur",
  },
  {
    id: 13,
    companyName: "Ganesh Fruits",
    ownerName: "Ganesh Hegde",
    email: "ganesh.hegde@gmail.com",
    phone: "9876543222",
    address: "1st Floor, Fruit Market, Mangalore",
    pincode: "575020",
    location: "Ullal",
    route: "Ullal to Thokkottu",
  },
  {
    id: 14,
    companyName: "Harini Clothing",
    ownerName: "Harini Shetty",
    email: "harini.shetty@gmail.com",
    phone: "9876543223",
    address: "Shop No 7, Fashion Street, Mangalore",
    pincode: "575020",
    location: "Thokkottu",
    route: "Thokkottu to Deralakatte",
  },
  {
    id: 15,
    companyName: "Elite Furnishings",
    ownerName: "Kiran Shenoy",
    email: "kiran.shenoy@gmail.com",
    phone: "9876543224",
    address: "2nd Floor, Elite Plaza, Mangalore",
    pincode: "575018",
    location: "Derlakatte",
    route: "Derlakatte to Ullal",
  },
  {
    id: 16,
    companyName: "Prakash Stationery",
    ownerName: "Prakash Rao",
    email: "prakash.rao@gmail.com",
    phone: "9876543225",
    address: "Shop No 3, Stationery Market, Mangalore",
    pincode: "575002",
    location: "Pumpwell",
    route: "Pumpwell to Kankanady",
  },
  {
    id: 17,
    companyName: "Vijay Electronics",
    ownerName: "Vijay Kumar",
    email: "vijay.kumar@gmail.com",
    phone: "9876543226",
    address: "1st Floor, Tech Hub, Mangalore",
    pincode: "575002",
    location: "Jeppu",
    route: "Jeppu to Pandeshwar",
  },
  {
    id: 18,
    companyName: "Lotus Café",
    ownerName: "Arun Rao",
    email: "arun.rao@gmail.com",
    phone: "9876543227",
    address: "Ground Floor, Lotus Building, Mangalore",
    pincode: "575006",
    location: "Urwa",
    route: "Urwa to Kottara",
  },
  {
    id: 19,
    companyName: "Mysore Tiles",
    ownerName: "Ashok Kumar",
    email: "ashok.kumar@gmail.com",
    phone: "9876543228",
    address: "Shop No 10, Tile Market, Mangalore",
    pincode: "574142",
    location: "Bajpe",
    route: "Bajpe to Kenjar",
  },
  {
    id: 20,
    companyName: "Coastal Hardware",
    ownerName: "Latha Rao",
    email: "latha.rao@gmail.com",
    phone: "9876543229",
    address: "1st Floor, Hardware Plaza, Mangalore",
    pincode: "574142",
    location: "Kenjar",
    route: "Kenjar to Bajpe",
  },
  {
    id: 21,
    companyName: "Shree Builders",
    ownerName: "Manoj Bhat",
    email: "manoj.bhat@gmail.com",
    phone: "9876543230",
    address: "2nd Floor, Builder's Hub, Mangalore",
    pincode: "575005",
    location: "Kulshekar",
    route: "Kulshekar to Bikarnakatte",
  },
  {
    id: 22,
    companyName: "A1 Constructions",
    ownerName: "Naveen Rai",
    email: "naveen.rai@gmail.com",
    phone: "9876543231",
    address: "Shop No 4, Construction Plaza, Mangalore",
    pincode: "575010",
    location: "Panambur",
    route: "Panambur to Baikampady",
  },
  {
    id: 23,
    companyName: "Star Fruits",
    ownerName: "Pooja Shenoy",
    email: "pooja.shenoy@gmail.com",
    phone: "9876543232",
    address: "Ground Floor, Fruit Market, Mangalore",
    pincode: "575010",
    location: "Baikampady",
    route: "Baikampady to Panambur",
  },
  {
    id: 24,
    companyName: "Spice Exports",
    ownerName: "Shilpa Pai",
    email: "shilpa.pai@gmail.com",
    phone: "9876543233",
    address: "1st Floor, Spice Building, Mangalore",
    pincode: "575008",
    location: "Bondel",
    route: "Bondel to Kavoor",
  },
  {
    id: 25,
    companyName: "King's Furniture",
    ownerName: "Ramachandra Rao",
    email: "ramachandra.rao@gmail.com",
    phone: "9876543234",
    address: "Shop No 6, Furniture Plaza, Mangalore",
    pincode: "575008",
    location: "Kavoor",
    route: "Kavoor to Bondel",
  },
  {
    id: 26,
    companyName: "Jewel Paradise",
    ownerName: "Pranav Shetty",
    email: "pranav.shetty@gmail.com",
    phone: "9876543235",
    address: "2nd Floor, Jewel Mall, Mangalore",
    pincode: "575013",
    location: "Kulur",
    route: "Kulur to Baikampady",
  },
  {
    id: 27,
    companyName: "Skyline Developers",
    ownerName: "Umesh Nayak",
    email: "umesh.nayak@gmail.com",
    phone: "9876543236",
    address: "3rd Floor, Skyline Tower, Mangalore",
    pincode: "575008",
    location: "Kavoor",
    route: "Kavoor to Urwa",
  },
  {
    id: 28,
    companyName: "Raja Electricals",
    ownerName: "Raja Kumar",
    email: "raja.kumar@gmail.com",
    phone: "9876543237",
    address: "Shop No 2, Electrical Market, Mangalore",
    pincode: "575006",
    location: "Derebail",
    route: "Derebail to Kottara",
  },
  {
    id: 29,
    companyName: "Green Leaf Organics",
    ownerName: "Sumitra Acharya",
    email: "sumitra.acharya@gmail.com",
    phone: "9876543238",
    address: "Shop No 5, Organic Market, Mangalore",
    pincode: "575007",
    location: "Padil",
    route: "Padil to Kankanady",
  },
  {
    id: 30,
    companyName: "Elite Décor",
    ownerName: "Veena Bhat",
    email: "veena.bhat@gmail.com",
    phone: "9876543239",
    address: "1st Floor, Décor Plaza, Mangalore",
    pincode: "575003",
    location: "Kodialbail",
    route: "Kodialbail to Hampankatta",
  },
];
export const mockOrderData = [
  { slNo: 1, orderId: "ORD001", date: "2024-10-01", customerName: "Rajesh Kumar", estimatedValue: "$100" },
  { slNo: 2, orderId: "ORD002", date: "2024-10-02", customerName: "Aditi Sharma", estimatedValue: "$150" },
  { slNo: 3, orderId: "ORD003", date: "2024-10-03", customerName: "Vikram Singh", estimatedValue: "$200" },
  { slNo: 4, orderId: "ORD004", date: "2024-10-04", customerName: "Sneha Patel", estimatedValue: "$250" },
  { slNo: 5, orderId: "ORD005", date: "2024-10-05", customerName: "Rahul Verma", estimatedValue: "$300" },
  { slNo: 6, orderId: "ORD006", date: "2024-10-06", customerName: "Neha Agarwal", estimatedValue: "$350" },
  { slNo: 7, orderId: "ORD007", date: "2024-10-07", customerName: "Ravi Mehta", estimatedValue: "$400" },
  { slNo: 8, orderId: "ORD008", date: "2024-10-08", customerName: "Kiran Reddy", estimatedValue: "$450" },
  { slNo: 9, orderId: "ORD009", date: "2024-10-09", customerName: "Pooja Jain", estimatedValue: "$500" },
  { slNo: 10, orderId: "ORD010", date: "2024-10-10", customerName: "Suresh Rao", estimatedValue: "$550" },
];

export const mockDataProducts = [
  {
    id: 1,
    productName: "A Barly 100gm",
    description: "Nutritious barley in a 100gm pack.",
    sku: "AB100",
    type: "Grains",
    unitOfMeasure: "gm",
    unitWeight: "10",
    standardMrp: "25",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 2,
    productName: "A Cardomom B 20/-",
    description: "High-quality cardamom for aromatic flavor.",
    sku: "ACB20",
    type: "Spices",
    unitOfMeasure: "gm",
    unitWeight: "8",
    standardMrp: "20",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 3,
    productName: "A Cashew B 20/-",
    description: "Fresh cashews in a 20gm pack.",
    sku: "ACB20",
    type: "Nuts",
    unitOfMeasure: "gm",
    unitWeight: "12",
    standardMrp: "20",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 4,
    productName: "A Chukku Kappi B 10/-",
    description: "Traditional herbal drink with dry ginger.",
    sku: "ACKB10",
    type: "Beverage",
    unitOfMeasure: "gm",
    unitWeight: "10",
    standardMrp: "10",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 5,
    productName: "A Chukku Kuppi B 20/-",
    description: "Spiced dry ginger tea mix for instant energy.",
    sku: "ACKB20",
    type: "Beverage",
    unitOfMeasure: "gm",
    unitWeight: "10",
    standardMrp: "20",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 6,
    productName: "Agarbatti",
    description: "Fragrant incense sticks to enhance atmosphere.",
    sku: "AGB10",
    type: "Aroma Products",
    unitOfMeasure: "pcs",
    unitWeight: "20",
    standardMrp: "15",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 7,
    productName: "A Kismis B 10/-",
    description: "Sweet raisins in a small 10gm pack.",
    sku: "AKB10",
    type: "Dry Fruits",
    unitOfMeasure: "gm",
    unitWeight: "8",
    standardMrp: "10",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 8,
    productName: "A Kondattam Mulaku B 10/-",
    description: "Sun-dried chillies for a spicy punch.",
    sku: "AKMB10",
    type: "Spices",
    unitOfMeasure: "gm",
    unitWeight: "10",
    standardMrp: "10",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 9,
    productName: "A Masala Koottu B 10/-",
    description: "Spiced mix for flavorful dishes.",
    sku: "AMB10",
    type: "Mixes",
    unitOfMeasure: "gm",
    unitWeight: "10",
    standardMrp: "10",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 10,
    productName: "A Masala Koottu B 30/-",
    description: "Premium spiced mix for a variety of dishes.",
    sku: "AMB30",
    type: "Mixes",
    unitOfMeasure: "gm",
    unitWeight: "10",
    standardMrp: "30",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 11,
    productName: "A Pavakka Kondattam B 10/-",
    description: "Sun-dried bitter gourd for adding crunch to meals.",
    sku: "APKB10",
    type: "Vegetables",
    unitOfMeasure: "gm",
    unitWeight: "8",
    standardMrp: "10",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 12,
    productName: "A Payasam B 20/-",
    description: "Delicious Payasam mix to prepare a sweet dish.",
    sku: "APM20",
    type: "Desserts",
    unitOfMeasure: "gm",
    unitWeight: "12",
    standardMrp: "20",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 13,
    productName: "A Pickle Cut Mango 200gm",
    description: "Tangy mango pickle in a 200gm pack.",
    sku: "APCM200",
    type: "Pickles",
    unitOfMeasure: "gm",
    unitWeight: "12",
    standardMrp: "35",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 14,
    productName: "A Pickle Garlic 200gm",
    description: "Spicy garlic pickle in a 200gm pack.",
    sku: "APG200",
    type: "Pickles",
    unitOfMeasure: "gm",
    unitWeight: "12",
    standardMrp: "30",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 15,
    productName: "A Pickle Lemon 200gm",
    description: "Tangy lemon pickle, perfect for meals.",
    sku: "APL200",
    type: "Pickles",
    unitOfMeasure: "gm",
    unitWeight: "12",
    standardMrp: "30",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 16,
    productName: "A Pickle Lemon 5kg",
    description: "Bulk pack of tangy lemon pickle.",
    sku: "APL5KG",
    type: "Pickles",
    unitOfMeasure: "kg",
    unitWeight: "30",
    standardMrp: "250",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 17,
    productName: "A Sauce Dark 200ml",
    description: "Rich, dark sauce for flavorful dishes.",
    sku: "ASD200",
    type: "Sauces",
    unitOfMeasure: "ml",
    unitWeight: "15",
    standardMrp: "35",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 18,
    productName: "A Sauce Tomato 1kg",
    description: "Tomato sauce in a 1kg bulk pack.",
    sku: "AST1KG",
    type: "Sauces",
    unitOfMeasure: "kg",
    unitWeight: "20",
    standardMrp: "120",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 19,
    productName: "A Soyaball Big 100gm",
    description: "Protein-packed big soyaballs.",
    sku: "ASBB100",
    type: "Protein",
    unitOfMeasure: "gm",
    unitWeight: "10",
    standardMrp: "40",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 20,
    productName: "A Soyaball Small B 10/-",
    description: "Smaller soyaballs, ideal for quick snacks.",
    sku: "ASSB10",
    type: "Protein",
    unitOfMeasure: "gm",
    unitWeight: "8",
    standardMrp: "10",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 21,
    productName: "Avo Kajoor Cake 50/-",
    description: "Delicious Kajoor cake with rich flavor.",
    sku: "AKC50",
    type: "Cakes",
    unitOfMeasure: "gm",
    unitWeight: "15",
    standardMrp: "50",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 22,
    productName: "Badam Drink 200ml",
    description: "Almond-based healthy drink in 200ml pack.",
    sku: "BD200",
    type: "Beverages",
    unitOfMeasure: "ml",
    unitWeight: "10",
    standardMrp: "20",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 23,
    productName: "B Fruit Fizz Jeera Masala 160ml",
    description: "Refreshing jeera masala flavored drink.",
    sku: "BFJ160",
    type: "Beverages",
    unitOfMeasure: "ml",
    unitWeight: "12",
    standardMrp: "20",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 24,
    productName: "B Fruzon Fizzy Apple 160ml",
    description: "Fizzy drink with a green apple twist.",
    sku: "BFFA160",
    type: "Beverages",
    unitOfMeasure: "ml",
    unitWeight: "12",
    standardMrp: "18",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 25,
    productName: "Baby Biscuit 50gm",
    description: "Nutritious biscuits for babies.",
    sku: "BB50",
    type: "Snacks",
    unitOfMeasure: "gm",
    unitWeight: "6",
    standardMrp: "15",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 26,
    productName: "Badam Mix 50gm",
    description: "Premium badam mix for milk.",
    sku: "BM50",
    type: "Mixes",
    unitOfMeasure: "gm",
    unitWeight: "8",
    standardMrp: "30",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 27,
    productName: "Balm 50gm",
    description: "Effective pain relief balm.",
    sku: "B50",
    type: "Health",
    unitOfMeasure: "gm",
    unitWeight: "4",
    standardMrp: "25",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 28,
    productName: "Banana Chips 100gm",
    description: "Crunchy and delicious banana chips.",
    sku: "BC100",
    type: "Snacks",
    unitOfMeasure: "gm",
    unitWeight: "8",
    standardMrp: "40",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 29,
    productName: "Barley Drink 200ml",
    description: "Refreshing barley-based drink.",
    sku: "BD200",
    type: "Beverages",
    unitOfMeasure: "ml",
    unitWeight: "12",
    standardMrp: "25",
    shippableFlag: "true",
    orderableFlag: "true",
  },
  {
    id: 30,
    productName: "Barley Rava 100gm",
    description: "Coarse barley grains for healthy dishes.",
    sku: "BR100",
    type: "Grains",
    unitOfMeasure: "gm",
    unitWeight: "10",
    standardMrp: "20",
    shippableFlag: "true",
    orderableFlag: "true",
  },
];

  
export const mockDataInvoices = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    cost: "21.24",
    phone: "(665)121-5454",
    date: "03/12/2022",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    cost: "1.24",
    phone: "(421)314-2288",
    date: "06/15/2021",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    cost: "11.24",
    phone: "(422)982-6739",
    date: "05/02/2022",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    cost: "80.55",
    phone: "(921)425-6742",
    date: "03/21/2022",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    cost: "1.24",
    phone: "(421)445-1189",
    date: "01/12/2021",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    cost: "63.12",
    phone: "(232)545-6483",
    date: "11/02/2022",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    cost: "52.42",
    phone: "(543)124-0123",
    date: "02/11/2022",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    cost: "21.24",
    phone: "(222)444-5555",
    date: "05/02/2021",
  },
];

export const mockTransactions = [
  {
    txId: "01e4dsa",
    user: "johndoe",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "51034szv",
    user: "goodmanave",
    date: "2022-11-05",
    cost: "200.95",
  },
  {
    txId: "0a123sb",
    user: "stevebower",
    date: "2022-11-02",
    cost: "13.55",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "120s51a",
    user: "wootzifer",
    date: "2019-04-15",
    cost: "24.20",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
];

export const mockBarData = [
  {
    country: "AD",
    "hot dog": 137,
    "hot dogColor": "hsl(229, 70%, 50%)",
    burger: 96,
    burgerColor: "hsl(296, 70%, 50%)",
    kebab: 72,
    kebabColor: "hsl(97, 70%, 50%)",
    donut: 140,
    donutColor: "hsl(340, 70%, 50%)",
  },
  {
    country: "AE",
    "hot dog": 55,
    "hot dogColor": "hsl(307, 70%, 50%)",
    burger: 28,
    burgerColor: "hsl(111, 70%, 50%)",
    kebab: 58,
    kebabColor: "hsl(273, 70%, 50%)",
    donut: 29,
    donutColor: "hsl(275, 70%, 50%)",
  },
  {
    country: "AF",
    "hot dog": 109,
    "hot dogColor": "hsl(72, 70%, 50%)",
    burger: 23,
    burgerColor: "hsl(96, 70%, 50%)",
    kebab: 34,
    kebabColor: "hsl(106, 70%, 50%)",
    donut: 152,
    donutColor: "hsl(256, 70%, 50%)",
  },
  {
    country: "AG",
    "hot dog": 133,
    "hot dogColor": "hsl(257, 70%, 50%)",
    burger: 52,
    burgerColor: "hsl(326, 70%, 50%)",
    kebab: 43,
    kebabColor: "hsl(110, 70%, 50%)",
    donut: 83,
    donutColor: "hsl(9, 70%, 50%)",
  },
  {
    country: "AI",
    "hot dog": 81,
    "hot dogColor": "hsl(190, 70%, 50%)",
    burger: 80,
    burgerColor: "hsl(325, 70%, 50%)",
    kebab: 112,
    kebabColor: "hsl(54, 70%, 50%)",
    donut: 35,
    donutColor: "hsl(285, 70%, 50%)",
  },
  {
    country: "AL",
    "hot dog": 66,
    "hot dogColor": "hsl(208, 70%, 50%)",
    burger: 111,
    burgerColor: "hsl(334, 70%, 50%)",
    kebab: 167,
    kebabColor: "hsl(182, 70%, 50%)",
    donut: 18,
    donutColor: "hsl(76, 70%, 50%)",
  },
  {
    country: "AM",
    "hot dog": 80,
    "hot dogColor": "hsl(87, 70%, 50%)",
    burger: 47,
    burgerColor: "hsl(141, 70%, 50%)",
    kebab: 158,
    kebabColor: "hsl(224, 70%, 50%)",
    donut: 49,
    donutColor: "hsl(274, 70%, 50%)",
  },
];

export const mockPieData = [
  {
    id: "hack",
    label: "hack",
    value: 239,
    color: "hsl(104, 70%, 50%)",
  },
  {
    id: "make",
    label: "make",
    value: 170,
    color: "hsl(162, 70%, 50%)",
  },
  {
    id: "go",
    label: "go",
    value: 322,
    color: "hsl(291, 70%, 50%)",
  },
  {
    id: "lisp",
    label: "lisp",
    value: 503,
    color: "hsl(229, 70%, 50%)",
  },
  {
    id: "scala",
    label: "scala",
    value: 584,
    color: "hsl(344, 70%, 50%)",
  },
];

export const mockLineData = [
  {
    id: "japan",
    color: tokens("dark").greenAccent[500],
    data: [
      {
        x: "plane",
        y: 101,
      },
      {
        x: "helicopter",
        y: 75,
      },
      {
        x: "boat",
        y: 36,
      },
      {
        x: "train",
        y: 216,
      },
      {
        x: "subway",
        y: 35,
      },
      {
        x: "bus",
        y: 236,
      },
      {
        x: "car",
        y: 88,
      },
      {
        x: "moto",
        y: 232,
      },
      {
        x: "bicycle",
        y: 281,
      },
      {
        x: "horse",
        y: 1,
      },
      {
        x: "skateboard",
        y: 35,
      },
      {
        x: "others",
        y: 14,
      },
    ],
  },
  {
    id: "france",
    color: tokens("dark").blueAccent[300],
    data: [
      {
        x: "plane",
        y: 212,
      },
      {
        x: "helicopter",
        y: 190,
      },
      {
        x: "boat",
        y: 270,
      },
      {
        x: "train",
        y: 9,
      },
      {
        x: "subway",
        y: 75,
      },
      {
        x: "bus",
        y: 175,
      },
      {
        x: "car",
        y: 33,
      },
      {
        x: "moto",
        y: 189,
      },
      {
        x: "bicycle",
        y: 97,
      },
      {
        x: "horse",
        y: 87,
      },
      {
        x: "skateboard",
        y: 299,
      },
      {
        x: "others",
        y: 251,
      },
    ],
  },
  {
    id: "us",
    color: tokens("dark").redAccent[200],
    data: [
      {
        x: "plane",
        y: 191,
      },
      {
        x: "helicopter",
        y: 136,
      },
      {
        x: "boat",
        y: 91,
      },
      {
        x: "train",
        y: 190,
      },
      {
        x: "subway",
        y: 211,
      },
      {
        x: "bus",
        y: 152,
      },
      {
        x: "car",
        y: 189,
      },
      {
        x: "moto",
        y: 152,
      },
      {
        x: "bicycle",
        y: 8,
      },
      {
        x: "horse",
        y: 197,
      },
      {
        x: "skateboard",
        y: 107,
      },
      {
        x: "others",
        y: 170,
      },
    ],
  },
];

export const mockGeographyData = [
  {
    id: "AFG",
    value: 520600,
  },
  {
    id: "AGO",
    value: 949905,
  },
  {
    id: "ALB",
    value: 329910,
  },
  {
    id: "ARE",
    value: 675484,
  },
  {
    id: "ARG",
    value: 432239,
  },
  {
    id: "ARM",
    value: 288305,
  },
  {
    id: "ATA",
    value: 415648,
  },
  {
    id: "ATF",
    value: 665159,
  },
  {
    id: "AUT",
    value: 798526,
  },
  {
    id: "AZE",
    value: 481678,
  },
  {
    id: "BDI",
    value: 496457,
  },
  {
    id: "BEL",
    value: 252276,
  },
  {
    id: "BEN",
    value: 440315,
  },
  {
    id: "BFA",
    value: 343752,
  },
  {
    id: "BGD",
    value: 920203,
  },
  {
    id: "BGR",
    value: 261196,
  },
  {
    id: "BHS",
    value: 421551,
  },
  {
    id: "BIH",
    value: 974745,
  },
  {
    id: "BLR",
    value: 349288,
  },
  {
    id: "BLZ",
    value: 305983,
  },
  {
    id: "BOL",
    value: 430840,
  },
  {
    id: "BRN",
    value: 345666,
  },
  {
    id: "BTN",
    value: 649678,
  },
  {
    id: "BWA",
    value: 319392,
  },
  {
    id: "CAF",
    value: 722549,
  },
  {
    id: "CAN",
    value: 332843,
  },
  {
    id: "CHE",
    value: 122159,
  },
  {
    id: "CHL",
    value: 811736,
  },
  {
    id: "CHN",
    value: 593604,
  },
  {
    id: "CIV",
    value: 143219,
  },
  {
    id: "CMR",
    value: 630627,
  },
  {
    id: "COG",
    value: 498556,
  },
  {
    id: "COL",
    value: 660527,
  },
  {
    id: "CRI",
    value: 60262,
  },
  {
    id: "CUB",
    value: 177870,
  },
  {
    id: "-99",
    value: 463208,
  },
  {
    id: "CYP",
    value: 945909,
  },
  {
    id: "CZE",
    value: 500109,
  },
  {
    id: "DEU",
    value: 63345,
  },
  {
    id: "DJI",
    value: 634523,
  },
  {
    id: "DNK",
    value: 731068,
  },
  {
    id: "DOM",
    value: 262538,
  },
  {
    id: "DZA",
    value: 760695,
  },
  {
    id: "ECU",
    value: 301263,
  },
  {
    id: "EGY",
    value: 148475,
  },
  {
    id: "ERI",
    value: 939504,
  },
  {
    id: "ESP",
    value: 706050,
  },
  {
    id: "EST",
    value: 977015,
  },
  {
    id: "ETH",
    value: 461734,
  },
  {
    id: "FIN",
    value: 22800,
  },
  {
    id: "FJI",
    value: 18985,
  },
  {
    id: "FLK",
    value: 64986,
  },
  {
    id: "FRA",
    value: 447457,
  },
  {
    id: "GAB",
    value: 669675,
  },
  {
    id: "GBR",
    value: 757120,
  },
  {
    id: "GEO",
    value: 158702,
  },
  {
    id: "GHA",
    value: 893180,
  },
  {
    id: "GIN",
    value: 877288,
  },
  {
    id: "GMB",
    value: 724530,
  },
  {
    id: "GNB",
    value: 387753,
  },
  {
    id: "GNQ",
    value: 706118,
  },
  {
    id: "GRC",
    value: 377796,
  },
  {
    id: "GTM",
    value: 66890,
  },
  {
    id: "GUY",
    value: 719300,
  },
  {
    id: "HND",
    value: 739590,
  },
  {
    id: "HRV",
    value: 929467,
  },
  {
    id: "HTI",
    value: 538961,
  },
  {
    id: "HUN",
    value: 146095,
  },
  {
    id: "IDN",
    value: 490681,
  },
  {
    id: "IND",
    value: 549818,
  },
  {
    id: "IRL",
    value: 630163,
  },
  {
    id: "IRN",
    value: 596921,
  },
  {
    id: "IRQ",
    value: 767023,
  },
  {
    id: "ISL",
    value: 478682,
  },
  {
    id: "ISR",
    value: 963688,
  },
  {
    id: "ITA",
    value: 393089,
  },
  {
    id: "JAM",
    value: 83173,
  },
  {
    id: "JOR",
    value: 52005,
  },
  {
    id: "JPN",
    value: 199174,
  },
  {
    id: "KAZ",
    value: 181424,
  },
  {
    id: "KEN",
    value: 60946,
  },
  {
    id: "KGZ",
    value: 432478,
  },
  {
    id: "KHM",
    value: 254461,
  },
  {
    id: "OSA",
    value: 942447,
  },
  {
    id: "KWT",
    value: 414413,
  },
  {
    id: "LAO",
    value: 448339,
  },
  {
    id: "LBN",
    value: 620090,
  },
  {
    id: "LBR",
    value: 435950,
  },
  {
    id: "LBY",
    value: 75091,
  },
  {
    id: "LKA",
    value: 595124,
  },
  {
    id: "LSO",
    value: 483524,
  },
  {
    id: "LTU",
    value: 867357,
  },
  {
    id: "LUX",
    value: 689172,
  },
  {
    id: "LVA",
    value: 742980,
  },
  {
    id: "MAR",
    value: 236538,
  },
  {
    id: "MDA",
    value: 926836,
  },
  {
    id: "MDG",
    value: 840840,
  },
  {
    id: "MEX",
    value: 353910,
  },
  {
    id: "MKD",
    value: 505842,
  },
  {
    id: "MLI",
    value: 286082,
  },
  {
    id: "MMR",
    value: 915544,
  },
  {
    id: "MNE",
    value: 609500,
  },
  {
    id: "MNG",
    value: 410428,
  },
  {
    id: "MOZ",
    value: 32868,
  },
  {
    id: "MRT",
    value: 375671,
  },
  {
    id: "MWI",
    value: 591935,
  },
  {
    id: "MYS",
    value: 991644,
  },
  {
    id: "NAM",
    value: 701897,
  },
  {
    id: "NCL",
    value: 144098,
  },
  {
    id: "NER",
    value: 312944,
  },
  {
    id: "NGA",
    value: 862877,
  },
  {
    id: "NIC",
    value: 90831,
  },
  {
    id: "NLD",
    value: 281879,
  },
  {
    id: "NOR",
    value: 224537,
  },
  {
    id: "NPL",
    value: 322331,
  },
  {
    id: "NZL",
    value: 86615,
  },
  {
    id: "OMN",
    value: 707881,
  },
  {
    id: "PAK",
    value: 158577,
  },
  {
    id: "PAN",
    value: 738579,
  },
  {
    id: "PER",
    value: 248751,
  },
  {
    id: "PHL",
    value: 557292,
  },
  {
    id: "PNG",
    value: 516874,
  },
  {
    id: "POL",
    value: 682137,
  },
  {
    id: "PRI",
    value: 957399,
  },
  {
    id: "PRT",
    value: 846430,
  },
  {
    id: "PRY",
    value: 720555,
  },
  {
    id: "QAT",
    value: 478726,
  },
  {
    id: "ROU",
    value: 259318,
  },
  {
    id: "RUS",
    value: 268735,
  },
  {
    id: "RWA",
    value: 136781,
  },
  {
    id: "ESH",
    value: 151957,
  },
  {
    id: "SAU",
    value: 111821,
  },
  {
    id: "SDN",
    value: 927112,
  },
  {
    id: "SDS",
    value: 966473,
  },
  {
    id: "SEN",
    value: 158085,
  },
  {
    id: "SLB",
    value: 178389,
  },
  {
    id: "SLE",
    value: 528433,
  },
  {
    id: "SLV",
    value: 353467,
  },
  {
    id: "ABV",
    value: 251,
  },
  {
    id: "SOM",
    value: 445243,
  },
  {
    id: "SRB",
    value: 202402,
  },
  {
    id: "SUR",
    value: 972121,
  },
  {
    id: "SVK",
    value: 319923,
  },
  {
    id: "SVN",
    value: 728766,
  },
  {
    id: "SWZ",
    value: 379669,
  },
  {
    id: "SYR",
    value: 16221,
  },
  {
    id: "TCD",
    value: 101273,
  },
  {
    id: "TGO",
    value: 498411,
  },
  {
    id: "THA",
    value: 506906,
  },
  {
    id: "TJK",
    value: 613093,
  },
  {
    id: "TKM",
    value: 327016,
  },
  {
    id: "TLS",
    value: 607972,
  },
  {
    id: "TTO",
    value: 936365,
  },
  {
    id: "TUN",
    value: 898416,
  },
  {
    id: "TUR",
    value: 237783,
  },
  {
    id: "TWN",
    value: 878213,
  },
  {
    id: "TZA",
    value: 442174,
  },
  {
    id: "UGA",
    value: 720710,
  },
  {
    id: "UKR",
    value: 74172,
  },
  {
    id: "URY",
    value: 753177,
  },
  {
    id: "USA",
    value: 658725,
  },
  {
    id: "UZB",
    value: 550313,
  },
  {
    id: "VEN",
    value: 707492,
  },
  {
    id: "VNM",
    value: 538907,
  },
  {
    id: "VUT",
    value: 650646,
  },
  {
    id: "PSE",
    value: 476078,
  },
  {
    id: "YEM",
    value: 957751,
  },
  {
    id: "ZAF",
    value: 836949,
  },
  {
    id: "ZMB",
    value: 714503,
  },
  {
    id: "ZWE",
    value: 405217,
  },
  {
    id: "KOR",
    value: 171135,
  },
];
