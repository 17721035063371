import { Box, Button, TextField, useTheme, Card, CardContent, Stepper, Step, StepLabel } from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { useState } from "react";

import { useNavigate } from "react-router-dom"; // Assuming you use react-router-dom for routing

const AddProduct = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate(); // For page navigation
  const steps = ["Product Info", "Dimensions and Pricing", "Flags and Additional Info"];

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleDownloadTemplate = () => {
    const csvData = [['description', 'sku', 'type', 'unitLength', 'unitWidth', 'unitHeight', 'sizeUom', 'standardMrp', 'orderableFlag', 'availableOnCredit', 'eoaDate', 'activeStartDate', 'activeEndDate', 'eosDate', 'uom', 'sellableFlag', 'unitWeight', 'weightUom', 'sellLooseItemsFlag', 'minOrderQuantity', 'maxOrderQuantity', 'status', 'standardLotSize', 'shippableFlag', 'returnableFlag', 'perishableFlag', 'orderableOnWeb', 'contents', 'contentPrice', 'code', 'exportRestrictionFlag', 'importRestrictionFlag', 'taxCode']];
    const csvString = csvData.map(e => e.join(",")).join("\n");
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    // saveAs(blob, 'product_template.csv');
  };

  const handleCsvUpload = (event) => {
    const file = event.target.files[0];
    console.log(file);
  };

  return (
    <Box m="20px">
      <Header title="Add Item" subtitle="Enter Item or Inventory Details" />
      
      {/* Download and Upload CSV Template Section */}
      

      <Card>
      <Box display="flex" justifyContent="flex-end" mb="20px">
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate("../ProductList")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin:"10px",
          }}
        >
          Manage Item
        </Button>
      </Box>
        <CardContent>
        <Box display="flex" justifyContent="space-between" mb="20px">
        <Button variant="contained" color="secondary" onClick={handleDownloadTemplate}>Download CSV Template</Button>
        <Button variant="contained" color="secondary" component="label">
          Import CSV
          <input type="file" hidden accept=".csv" onChange={handleCsvUpload} />
        </Button>
      </Box>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel
                  sx={{
                    '& .MuiStepLabel-label': {
                      color: activeStep === index ? colors.blueAccent[700] : colors.grey[500],
                      fontWeight: activeStep === index ? "bold" : "normal",
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {activeStep === 0 && (
                  <Box>
                    
                    <TextField
                      fullWidth
                      required
                      id="ItemNo"
                      label="ItemNo"
                      value={values.ItemNo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.ItemNo && Boolean(errors.ItemNo)}
                      helperText={touched.ItemNo && errors.ItemNo}
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      required
                      id="description"
                      label="Description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.description && Boolean(errors.description)}
                      helperText={touched.description && errors.description}
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      required
                      id="sku"
                      label="SKU"
                      value={values.sku}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.sku && Boolean(errors.sku)}
                      helperText={touched.sku && errors.sku}
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      required
                      id="type"
                      label="Type"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.type && Boolean(errors.type)}
                      helperText={touched.type && errors.type}
                      margin="normal"
                    />
                  </Box>
                )}
                {activeStep === 1 && (
                  <Box>
                    <TextField
                      fullWidth
                      required
                      id="unitOfMeasure"
                      label="Unit Of Measure"
                      value={values.unitOfMeasure}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.unitOfMeasure && Boolean(errors.unitOfMeasure)}
                      helperText={touched.unitOfMeasure && errors.unitOfMeasure}
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      required
                      id="unitweight"
                      label="Unit weight"
                      value={values.unitweight}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.unitweight && Boolean(errors.unitweight)}
                      helperText={touched.unitweight && errors.unitweight}
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      required
                      id="standardMrp"
                      label="Standard MRP"
                      type="number"
                      value={values.standardMrp}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.standardMrp && Boolean(errors.standardMrp)}
                      helperText={touched.standardMrp && errors.standardMrp}
                      margin="normal"
                    />
                  </Box>
                )}
                {activeStep === 2 && (
                  <Box>
                    <TextField
                      fullWidth
                      required
                      id="shippableFlag"
                      label="Shippable Flag"
                      value={values.shippableFlag}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.shippableFlag && Boolean(errors.shippableFlag)}
                      helperText={touched.shippableFlag && errors.shippableFlag}
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      required
                      id="returnableFlag"
                      label="Returnable Flag"
                      value={values.returnableFlag}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.returnableFlag && Boolean(errors.returnableFlag)}
                      helperText={touched.returnableFlag && errors.returnableFlag}
                      margin="normal"
                    />
                  </Box>
                )}

<Box display="flex" justifyContent="space-between" mt="20px">
                  <Button 
                  
                  color="secondary"
                  variant="contained"
                  disabled={activeStep === 0} onClick={handleBack}
                  sx={{
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}>
                    Back
                  </Button>
                  <Button 
                  color="primary"
                  variant="contained"
                  onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}>
                    {activeStep === steps.length - 1 ? "Add Product" : "Next"}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  description: yup.string().required("Required"),
  sku: yup.string().required("Required"),
  type: yup.string().required("Required"),
  unitOfMeasure: yup.number().required("Required"),
  unitweight: yup.number().required("Required"),
  standardMrp: yup.number().required("Required"),
  shippableFlag: yup.string().required("Required"),
  returnableFlag: yup.string().required("Required"),
});

const initialValues = {
  description: "",
  sku: "",
  type: "",
  unitOfMeasure: "",
  unitWeight: "",
  standardMrp: "",
  shippableFlag: "",
  returnableFlag: "",
};

export default AddProduct;
