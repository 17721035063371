import React from 'react'
import configuration from './images/configuration.png'
import order from './images/order.png'
import delivery from './images/delivery.png'
import collection from './images/collection.png'
const Menu = () => {
  return (
    <div style={{ position: 'absolute', top: '50px', left: '100px', zIndex: 10000, padding: '10px', width: '230px', backgroundColor: '#ffffff', border: '1px solid #e0e0e0', borderRadius: '10px', boxShadow: '1px 1px 25px #000' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center' }}>
        <div style={{ textAlign: 'center', margin: '5px' }}>
          <img src={configuration} alt="Configuration Icon" width="80px" />
          <p style={{ margin: '5px 0 0 0', color: '#01357d' }}>Configuration</p>
        </div>
        <div style={{ textAlign: 'center', margin: '5px' }}>
          <img src={order} alt="Order Icon" width="80px" />
          <p style={{ margin: '5px 0 0 0', color: '#01357d' }}>Order</p>
        </div>
        <div style={{ textAlign: 'center', margin: '5px' }}>
          <img src={delivery} alt="Delivery Icon" width="80px" />
          <p style={{ margin: '5px 0 0 0', color: '#01357d' }}>Delivery</p>
        </div>
        <div style={{ textAlign: 'center', margin: '5px' }}>
          <img src={collection} alt="Collection Icon" width="80px" />
          <p style={{ margin: '5px 0 0 0', color: '#01357d' }}>Collection</p>
        </div>
      </div>
    </div>

  )
}

export default Menu