import { Box, Button, Modal, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataProducts } from "../../data/productData";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useState } from "react";

const itemLocations = [
  { code: "R1", name: "Room No: 1" },
  { code: "R2", name: "Room No: 2" },
  { code: "SR1", name: "Store Room: 1" },
  { code: "SR2", name: "Store Room: 2" },
  // Add more locations as needed
];

const ProductList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);  // For viewing modal
  const [assignedLocation, setAssignedLocation] = useState(null);  // For assigning modal
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAssignLocation, setSelectedAssignLocation] = useState(null); // To track selected location in the modal

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "productName",
      headerName: "Product Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "unitOfMeasure",
      headerName: "Unit of Measure",
      flex: 1,
    },
    {
      field: "unitWeight",
      headerName: "Unit Weight",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "standardMrp",
      headerName: "Standard MRP",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "shippableFlag",
      headerName: "Shippable",
      type: "boolean",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "orderableFlag",
      headerName: "Orderable",
      type: "boolean",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "itemLocation",
      headerName: "Item Location",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Button onClick={() => handleAssignClick(params.row)}>Assign</Button>
          <Button onClick={() => handleViewClick(params.row)}>View</Button>
        </Box>
      ),
    },
  ];

  const handleAssignClick = (row) => {
    setOpenAssignModal(true);
    setAssignedLocation(row); // Save the row for context if needed
  };

  const handleViewClick = (row) => {
    setSelectedLocation(row.itemLocation); // Set the location for viewing
    setOpenViewModal(true);
  };

  const handleCloseAssignModal = () => {
    setOpenAssignModal(false);
    setSearchTerm(""); // Reset search term when closing
    setSelectedAssignLocation(null); // Reset selected location when closing
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setSelectedLocation(null); // Reset selected location when closing
  };

  const handleSelectLocation = (location) => {
    setSelectedAssignLocation(location); // Store the selected location
  };

  const handleSaveLocation = () => {
    if (selectedAssignLocation) {
      // Logic to save the assigned location
      console.log("Assigned Location:", selectedAssignLocation);
      // Update the item row or send the selected location to the server here
    }
    handleCloseAssignModal();
  };

  return (
    <Box m="20px">
      <Header
        title="Manage Items"
        subtitle="List of Items in the Inventory"
      />
      <Box
        m="-25px 0 0 0"
        height="78vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid grey",
          },
          "& .name-column--cell": {
            color: colors.blueAccent[400],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[900],
            height: "5vh"
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={mockDataProducts}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>

      {/* Assign Location Modal */}
      <Modal open={openAssignModal} onClose={handleCloseAssignModal}>
        <Box sx={{ padding: 4, bgcolor: "background.paper", borderRadius: 2 }}>
          <h2>Assign Location</h2>
          <TextField
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Box
            sx={{
              maxHeight: 300,
              overflowY: "auto",
              marginBottom: 2,
              border: "1px solid lightgray",
              borderRadius: 1,
            }}
          >
            {itemLocations
              .filter((location) =>
                location.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((location) => (
                <Box key={location.code} sx={{ display: "flex", justifyContent: "space-between", padding: 1 }}>
                  <span>{location.name} (Code: {location.code})</span>
                  <Button onClick={() => handleSelectLocation(location)}>Select</Button>
                </Box>
              ))}
          </Box>
          {selectedAssignLocation && (
            <p>Selected Location: {selectedAssignLocation.name} (Code: {selectedAssignLocation.code})</p>
          )}
          <Button variant="contained" onClick={handleSaveLocation}>Save</Button>
        </Box>
      </Modal>

      {/* View Location Modal */}
      <Modal open={openViewModal} onClose={handleCloseViewModal}>
        <Box sx={{ padding: 4, bgcolor: "background.paper", borderRadius: 2 }}>
          <h2>View Location</h2>
          <p>{selectedLocation ? `Selected Location: ${selectedLocation}` : "Store Room: 2(code:SR2)."}</p>
          <Button variant="contained" onClick={handleCloseViewModal}>Close</Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProductList;
