
import { useState } from "react";
import { Box, IconButton, useTheme, Card } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AppsIcon from '@mui/icons-material/Apps';
import SearchIcon from "@mui/icons-material/Search";
import Menu from "./Menu";
const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [showCard, setShowCard] = useState(false);

  const handleIconClick = () => {
    console.log('Icon clicked');
    setShowCard(!showCard);
    console.log('showCard:', !showCard);
  };
  

  return (
    <Box display="flex" justifyContent="space-between" p={2} backgroundColor={colors.primary[400]} style={{boxShadow:"3px 1px 10px #a5a6a8"}}>
      <Box display="flex">
        <IconButton onClick={handleIconClick}>
          <AppsIcon />
        </IconButton>
      </Box>
      {/* SEARCH BAR */}
      <Box
        display=""
        backgroundColor={colors.primary[400]}
        borderRadius="10px"
        border="1px solid #a5a6a8"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton onClick={handleIconClick}>
        <NotificationsOutlinedIcon />
      </IconButton>
      <IconButton>
        <SettingsOutlinedIcon />
      </IconButton>
      {showCard && (
        <Card>
        <Menu/>
      </Card>
      )}
        <IconButton>
          <PersonOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
