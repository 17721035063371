import React, { useState } from 'react';
import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme"; // Import tokens for colors
import { useTheme } from "@mui/material";
import { Link } from "react-router-dom"; // Link for navigating to the invoice

const mockOrderData = [
  { id: 1, slNo: 1, orderId: "ORD001", date: "2024-10-01", customerName: "Rajesh Kumar", estimatedValue: "$100", status: "Pending" },
  { id: 2, slNo: 2, orderId: "ORD002", date: "2024-10-02", customerName: "Aditi Sharma", estimatedValue: "$150", status: "Pending" },
  { id: 3, slNo: 3, orderId: "ORD003", date: "2024-10-03", customerName: "Vikram Singh", estimatedValue: "$200", status: "Pending" },
  { id: 4, slNo: 4, orderId: "ORD004", date: "2024-10-04", customerName: "Sneha Patel", estimatedValue: "$250", status: "Pending" },
  { id: 5, slNo: 5, orderId: "ORD005", date: "2024-10-05", customerName: "Rahul Verma", estimatedValue: "$300", status: "Pending" },
  { id: 6, slNo: 6, orderId: "ORD006", date: "2024-10-06", customerName: "Neha Agarwal", estimatedValue: "$350", status: "Pending" },
  { id: 7, slNo: 7, orderId: "ORD007", date: "2024-10-07", customerName: "Ravi Mehta", estimatedValue: "$400", status: "Pending" },
  { id: 8, slNo: 8, orderId: "ORD008", date: "2024-10-08", customerName: "Kiran Reddy", estimatedValue: "$450", status: "Pending" },
  { id: 9, slNo: 9, orderId: "ORD009", date: "2024-10-09", customerName: "Pooja Jain", estimatedValue: "$500", status: "Pending" },
  { id: 10, slNo: 10, orderId: "ORD010", date: "2024-10-10", customerName: "Suresh Rao", estimatedValue: "$550", status: "Pending" },
];

const OrderList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); // Use tokens for color theme
  const [rows, setRows] = useState(mockOrderData);
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    { field: "slNo", headerName: "Sl. No.", flex: 0.5 },
    { field: "orderId", headerName: "Order ID", flex: 1 },
    { field: "date", headerName: "Date", flex: 1 },
    { field: "customerName", headerName: "Customer Name", flex: 1 },
    { field: "estimatedValue", headerName: "Estimated Value", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 }, // Added Status column
    {
      field: "viewEstimate",
      headerName: "View Estimate",
      flex: 1,
      renderCell: (params) => (
        <Link to={`/invoice`}>
          <button
            style={{
              padding: "5px 10px",
              backgroundColor: colors.blueAccent[700], // Using color token
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            View
          </button>
        </Link>
      ),
    },
  ];

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  const handleApproveOrders = () => {
    const updatedRows = rows.map((row) => {
      if (selectedRows.includes(row.id)) {
        return { ...row, status: "Approved" }; // Update status to Approved
      }
      return row;
    });

    setRows(updatedRows); // Set updated rows with new statuses
    console.log("Approved Orders:", selectedRows);
  };

  return (
    <Box m="20px">
      <h1 style={{ color: colors.blueAccent[400] }}>Order List</h1>
      <Box
        m="-25px 0 0 0"
        height="60vh" // Adjusted height for the table
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid grey",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[900], // Header color
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[900],
            height: "5vh"
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={rows} // Use state rows
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection // Enables checkbox selection
          onSelectionModelChange={handleSelectionChange} // Track selected rows
        />
      </Box>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={handleApproveOrders}
          style={{
            backgroundColor: colors.greenAccent[500],
            color: "white",
            padding: "10px 20px",
          }}
        >
          Approve Order
        </Button>
      </Box>
    </Box>
  );
};

export default OrderList;
