import React from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';
import './Invoice.css';

const Invoice = () => {
  const location = useLocation();

  // Dummy customer data
  const customerDetails = {
    id: 3,
    companyName: "Udupi Sweets",
    ownerName: "Suresh Kamath",
    email: "suresh.kamath@gmail.com",
    phone: "9876543212",
    address: "2nd Floor, Empire Mall, Mangalore",
    pincode: "575002",
    location: "Kankanady",
    route: "Kankanady to Kadri",
  };

  // Dummy order details
  const orderDetails = [
    {
      productName: "A Barly 100gm",
      sku: "AB100",
      quantity: 2,
      price: 25,
      total: 50,
    },
    {
      productName: "A Cardomom B 20/-",
      sku: "ACB20",
      quantity: 5,
      price: 20,
      total: 100,
    },
    {
      productName: "A Cashew B 20/-",
      sku: "ACB20",
      quantity: 3,
      price: 20,
      total: 60,
    },
    {
      productName: "A Chukku Kappi B 10/-",
      sku: "ACKB10",
      quantity: 4,
      price: 10,
      total: 40,
    },
  ];

  // Calculate total amount
  const totalAmount = orderDetails.reduce((acc, item) => acc + item.total, 0);
  const gstAmount = totalAmount * 0.18; // Assuming 18% GST
  const finalAmount = totalAmount + gstAmount;

  const handlePrint = () => {
    const printContent = document.getElementById('invoice');
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Invoice</title>');
    printWindow.document.write('<link rel="stylesheet" href="/path/to/your/Invoice.css" />'); // Add the correct path to your CSS file
    printWindow.document.write('</head><body >');
    printWindow.document.write(printContent.innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <Box m="40px">
      <div id="invoice" className="invoice-container">
        <Typography variant="h4" align="center" className="company-name">Skanda Sales</Typography>
        <Typography variant="h6" align="center">Estimate</Typography>

        <div className="customer-details">
          <Typography variant="h6">
            <strong>Customer Name:</strong> {customerDetails.companyName} <br />
            <strong>Owner Name:</strong> {customerDetails.ownerName} <br />
            <strong>Email:</strong> {customerDetails.email} <br />
            <strong>Phone:</strong> {customerDetails.phone} <br />
          </Typography>
          <Typography variant="h6" align="right">
            <strong>Address:</strong> {customerDetails.address} <br />
            <strong>Pincode:</strong> {customerDetails.pincode} <br />
            <strong>Location:</strong> {customerDetails.location} <br />
            <strong>Route:</strong> {customerDetails.route} <br />
          </Typography>
        </div>

        <Typography variant="h6" className="order-details-title">Order Details</Typography>
        
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Product Name</strong></TableCell>
                <TableCell><strong>SKU</strong></TableCell>
                <TableCell><strong>Quantity</strong></TableCell>
                <TableCell><strong>Price</strong></TableCell>
                <TableCell><strong>Total</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderDetails.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.productName}</TableCell>
                  <TableCell>{item.sku}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>Rs. {item.price}</TableCell>
                  <TableCell>Rs. {item.total}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={4} align="right"><strong>Subtotal:</strong></TableCell>
                <TableCell>Rs. {totalAmount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="right"><strong>GST (18%):</strong></TableCell>
                <TableCell>Rs. {gstAmount.toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} align="right"><strong>Final Amount:</strong></TableCell>
                <TableCell><strong>Rs. {finalAmount.toFixed(2)}</strong></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h6" className="gst-details">
          <strong>GST Registration Number:</strong> 29XXXXXXXXXX <br />
          <strong>GST Payable:</strong> Rs. {gstAmount.toFixed(2)}
        </Typography>

        <Typography variant="h6" className="terms-title">Terms and Conditions</Typography>
        <Typography className="terms-and-conditions">
          1. The payment is due within 30 days of the invoice date.<br />
          2. All goods remain the property of Skanda Sales until paid in full.<br />
          3. Please quote the invoice number in all correspondence.<br />
          4. For any discrepancies, please contact us within 7 days of receipt.<br />
          5. Subject to our terms of sale which are available on request.<br />
        </Typography>
      </div>

      <Button variant="contained" color="primary" onClick={handlePrint} style={{ marginTop: '20px', marginRight: '10px' }}>
        Print Invoice
      </Button>
    </Box>
  );
};

export default Invoice;
