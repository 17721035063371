import { Box, Button, TextField, useTheme, Card, CardContent, Stepper, Step, StepLabel, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { useState } from "react";
import { useNavigate } from "react-router-dom"; // Assuming you use react-router-dom for routing

const CreateCustomer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeStep, setActiveStep] = useState(0); // To manage active step
  const navigate = useNavigate(); // For page navigation

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <Box m="20px">
      <Header title="Add Customer" subtitle="Create a New Customer Profile" />
      {/* View Customer List Button */}
      <Box display="flex" justifyContent="flex-end" mb="20px">
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate("../CustomerList")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          Organization Customer
        </Button>&nbsp;&nbsp;
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate("../CustomerList")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          Manage Customer
        </Button> 
      </Box>
      <Card>
        <CardContent>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Stepper for multi-step navigation */}
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={index}>
                      <StepLabel
                        sx={{
                          '& .MuiStepLabel-label': {
                            color: activeStep === index ? colors.blueAccent[700] : colors.grey[500],
                            fontWeight: activeStep === index ? "bold" : "normal",
                          },
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <Box mt="20px">
                  {/* Step 1: User Info */}
                  {activeStep === 0 && (
                    <Box>
                      <TextField
                        fullWidth
                        required
                        id="companyName"
                        label="Company Name"
                        value={values.companyName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.companyName && Boolean(errors.companyName)}
                        helperText={touched.companyName && errors.companyName}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        required
                        id="ownerName"
                        label="Owner Name"
                        value={values.ownerName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.ownerName && Boolean(errors.ownerName)}
                        helperText={touched.ownerName && errors.ownerName}
                        margin="normal"
                      />

                      <Box display="flex" justifyContent="space-between">
                        <TextField
                          fullWidth
                          required
                          id="email"
                          label="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          margin="normal"
                          sx={{ mr: 2 }}
                        />
                        <TextField
                          fullWidth
                          required
                          id="phoneNo"
                          label="Phone No"
                          value={values.phoneNo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.phoneNo && Boolean(errors.phoneNo)}
                          helperText={touched.phoneNo && errors.phoneNo}
                          margin="normal"
                          sx={{ mr: 2 }}
                        />
                      </Box>
                    </Box>
                  )}

                  {/* Step 2: Location */}
                  {activeStep === 1 && (
                    <Box>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => navigate("../CreateLocation")} // Navigate to create new location page
                        sx={{ mb: 2 }}
                      >
                        Create New Location
                      </Button>
                      <TextField
                        fullWidth
                        required
                        id="pincode"
                        label="Pincode"
                        value={values.pincode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.pincode && Boolean(errors.pincode)}
                        helperText={touched.pincode && errors.pincode}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        select
                        id="location"
                        name="location"
                        label="Select Location"
                        value={values.location}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.location && Boolean(errors.location)}
                        helperText={touched.location && errors.location}
                        margin="normal"
                      >
                        {locationOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  )}

                  {/* Step 3: Route */}
                  {activeStep === 2 && (
                    <Box>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => navigate("/create-route")} // Navigate to create new route page
                        sx={{ mb: 2 }}
                      >
                        Create New Route
                      </Button>
                      <TextField
                        fullWidth
                        select
                        id="route"
                        name="route"
                        label="Select Route"
                        value={values.route}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.route && Boolean(errors.route)}
                        helperText={touched.route && errors.route}
                        margin="normal"
                      >
                        {routeOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  )}
                </Box>
                <Box display="flex" justifyContent="space-between" mt="20px">
                  <Button 
                  
                  color="secondary"
                  variant="contained"
                  disabled={activeStep === 0} onClick={handleBack}
                  sx={{
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}>
                    Back
                  </Button>
                  <Button 
                  color="primary"
                  variant="contained"
                  onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}>
                    {activeStep === steps.length - 1 ? "Create Customer" : "Next"}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  companyName: yup.string().required("required"),
  ownerName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  phoneNo: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  location: yup.string().required("required"),
  pincode: yup.string().required("required"),
  route: yup.string().required("required"),
});

const initialValues = {
  companyName: "",
  ownerName: "",
  email: "",
  phoneNo: "",
  location: "",
  pincode: "",
  route: "",
};

const steps = ["User Info", "Location", "Route"];

const locationOptions = [
  { value: "Bejai", label: "Bejai" },
  { value: "Kadri", label: "Kadri" },
];

const routeOptions = [
  { value: "Bejai to Bendoor", label: "Bejai to Bendoor" },
  { value: "Kadri to Bejai", label: "Kadri to Bejai" },
];

export default CreateCustomer;
