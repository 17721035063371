import {
  Box,
  Button,
  TextField,
  useTheme,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import Header from "../../components/Header";

const AddUser = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState(initialUsers);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRoutes, setSelectedRoutes] = useState({});
  const [filter, setFilter] = useState('');

  const handleFormSubmit = (values, { resetForm }) => {
    setUsers([...users, { ...values, id: users.length + 1, routes: [] }]);
    resetForm();
  };

  const handleDelete = (id) => {
    setUsers(users.filter((user) => user.id !== id));
  };

  const handleAssign = (user) => {
    setSelectedUser(user);
    setOpenModal(true);
  };

  const handleSaveRoutes = () => {
    const updatedUsers = users.map((user) =>
      user.id === selectedUser.id ? { ...user, routes: Object.keys(selectedRoutes).filter(routeId => selectedRoutes[routeId]).map(routeId => initialRoutes.find(route => route.id === parseInt(routeId)).route) } : user
    );
    setUsers(updatedUsers);
    setOpenModal(false);
  };

  const handleRouteChange = (routeId) => {
    setSelectedRoutes((prev) => ({
      ...prev,
      [routeId]: !prev[routeId], // Toggle the route selection
    }));
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Box m="20px">
      <Header title="Add User" subtitle="Enter User Details" />
      <Card>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={userSchema}
            onSubmit={handleFormSubmit}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {/* First Row with 3 fields */}
                  <Grid item xs={4}>
                    <TextField
                      id="name"
                      label="Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="username"
                      label="User Name"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.username && Boolean(errors.username)}
                      helperText={touched.username && errors.username}
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="email"
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      margin="normal"
                      fullWidth
                    />
                  </Grid>

                  {/* Second Row with 3 fields */}
                  <Grid item xs={4}>
                    <TextField
                      id="phone"
                      label="Phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.phone && Boolean(errors.phone)}
                      helperText={touched.phone && errors.phone}
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="address"
                      label="Address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.address && Boolean(errors.address)}
                      helperText={touched.address && errors.address}
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="role-label">Role</InputLabel>
                      <Select
                        labelId="role-label"
                        id="role"
                        label="Role"
                        value={values.role}
                        onChange={handleChange}
                      >
                        <MenuItem value="Specialist">Specialist</MenuItem>
                        <MenuItem value="Back Office Manager">Back Office Manager</MenuItem>
                        <MenuItem value="Admin">Admin</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    mt: 2,
                  }}
                >
                  Add User
                </Button>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>

      <Box mt="20px" style={{ height: "300px", overflow: "auto" }}>
        <Table style={{ backgroundColor: "white" }}>
          <TableHead>
            <TableRow>
              <TableCell>Sl.No.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Assigned Routes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <TableRow key={user.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>{user.address}</TableCell>
                <TableCell>
                  {user.routes.length > 0 ? user.routes.map((route, idx) => <div key={idx}>{route}</div>) : 'None'}
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => handleAssign(user)}
                    color="primary"
                    variant="outlined"
                    sx={{ mr: 1 }}
                  >
                    Assign
                  </Button>
                  <IconButton onClick={() => handleDelete(user.id)} color="secondary">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      {/* Modal for assigning routes */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxHeight: '80%',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <h2>Select Routes for {selectedUser?.name}</h2>
          <TextField
            label="Filter Routes"
            variant="outlined"
            value={filter}
            onChange={handleFilterChange}
            fullWidth
            margin="normal"
          />
          <Table style={{ backgroundColor: "white" }}>
            <TableHead>
              <TableRow>
                <TableCell>Select</TableCell>
                <TableCell>Route</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {initialRoutes.filter(route => route.route.toLowerCase().includes(filter.toLowerCase())).map((route) => (
                <TableRow key={route.id}>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedRoutes[route.id] || false}
                          onChange={() => handleRouteChange(route.id)}
                        />
                      }
                      label=""
                    />
                  </TableCell>
                  <TableCell>{route.route}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            onClick={handleSaveRoutes}
            color="primary"
            variant="contained"
            sx={{ mt: 2 }}
          >
            Save Routes
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

const userSchema = yup.object().shape({
  name: yup.string().required("required"),
  username: yup.string().required("required"),
  email: yup.string().email("Invalid email").required("required"),
  phone: yup.string().required("required"),
  address: yup.string().required("required"),
  role: yup.string().required("required"),
});

const initialValues = {
  name: "",
  username: "",
  email: "",
  phone: "",
  address: "",
  role: ""
};


  
  // Pre-defined users to populate the table initially
  const initialUsers = [
    { id: 1, name: "Rajesh Kumar", email: "rajeshkumar@gmail.com", phone: "9876543210", address: "1, Main Road, Bangalore", routes: [] },
    { id: 2, name: "Aditi Sharma", email: "aditisharma@gmail.com", phone: "8765432109", address: "2, Park Avenue, Mangalore", routes: [] },
    { id: 3, name: "Vikram Singh", email: "vikramsingh@gmail.com", phone: "7654321098", address: "3, Beach Road, Mangalore", routes: [] },
    { id: 4, name: "Sneha Patel", email: "snehapatel@gmail.com", phone: "6543210987", address: "4, Hilltop, Mangalore", routes: [] },
    { id: 5, name: "Rahul Verma", email: "rahulverma@gmail.com", phone: "5432109876", address: "5, River Side, Mangalore", routes: [] },
    { id: 6, name: "Neha Agarwal", email: "nehaagarwal@gmail.com", phone: "4321098765", address: "6, City Center, Mangalore", routes: [] },
    { id: 7, name: "Ravi Mehta", email: "ravimehta@gmail.com", phone: "3210987654", address: "7, Market Square, Mangalore", routes: [] },
    { id: 8, name: "Kiran Reddy", email: "kiranreddy@gmail.com", phone: "2109876543", address: "8, Lake View, Mangalore", routes: [] },
  ];
  
  const initialRoutes = [
    { id: 1, route: "Karangalpady to Balmatta" },
    { id: 2, route: "Bejai to Bendoor" },
    { id: 3, route: "Kankanady to Kadri" },
    { id: 4, route: "Bendoor to Falnir" },
    { id: 5, route: "Kadri to Bejai" },
    { id: 6, route: "Hampankatta to Kodialbail" },
    { id: 7, route: "Balmatta to Kadri" },
    { id: 8, route: "Falnir to Kankanady" },
    { id: 9, route: "Bikarnakatte to Kulshekar" },
    { id: 10, route: "Kottara to Surathkal" },
    { id: 11, route: "Surathkal to Panambur" },
  ];
  
  export default AddUser;
  