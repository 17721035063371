import { Box, Button, Modal, TextField, Switch, FormControlLabel } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataCustomers } from "../../data/productData";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import React, { useState } from "react";

const CustomerList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openModal, setOpenModal] = useState(false);
  const [priceRule, setPriceRule] = useState("default");
  const [paymentTerm, setPaymentTerm] = useState("default");
  const [creditCheck, setCreditCheck] = useState(false);
  const [creditAmount, setCreditAmount] = useState("");
  const [override, setOverride] = useState(false);
  const [overrideCount, setOverrideCount] = useState("");
  const [savedDetails, setSavedDetails] = useState([]);

  const handleSave = () => {
    const newDetail = {
      priceRule,
      paymentTerm,
      creditAmount: creditCheck ? creditAmount : null,
      overrideCount: override ? overrideCount : null,
    };
    setSavedDetails((prev) => [...prev, newDetail]);
    handleCloseModal();
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    // Reset form fields
    setPriceRule("default");
    setPaymentTerm("default");
    setCreditCheck(false);
    setCreditAmount("");
    setOverride(false);
    setOverrideCount("");
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "companyName", headerName: "Company Name", flex: 1 },
    { field: "ownerName", headerName: "Owner Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone", headerName: "Phone Number", type: "number", flex: 1 },
    { field: "address", headerName: "Address", flex: 1 },
    { field: "pincode", headerName: "Pincode", type: "number", flex: 0.5 },
    { field: "location", headerName: "Location", flex: 1 },
    { field: "route", headerName: "Route", flex: 1 },
    {
      field: "profile",
      headerName: "Profile",
      flex: 0.5,
      renderCell: () => (
        <Button variant="contained" onClick={handleOpenModal}>
          Manage
        </Button>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Customer List" subtitle="List of Registered Customers" />
      <Box
        m="-25px 0 0 0"
        height="78vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid grey",
          },
          "& .name-column--cell": {
            color: colors.blueAccent[400],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[900],
            height: "5vh",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid rows={mockDataCustomers} columns={columns} components={{ Toolbar: GridToolbar }} />
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <h2>Manage Profile</h2>
          <div>
            <label>Price Rules:</label>
            <select value={priceRule} onChange={(e) => setPriceRule(e.target.value)}>
              <option value="default">Default</option>
              <option value="rule 1">Rule 1</option>
              <option value="rule 2">Rule 2</option>
            </select>
          </div>
          <div>
            <label>Payment Terms:</label>
            <select value={paymentTerm} onChange={(e) => setPaymentTerm(e.target.value)}>
              <option value="default">Default</option>
              <option value="intermediate">Intermediate</option>
            </select>
          </div>
          <FormControlLabel
            control={<Switch checked={creditCheck} onChange={() => setCreditCheck(!creditCheck)} />}
            label="Credit Check"
          />
          {creditCheck && (
            <TextField
              label="Credit Amount"
              value={creditAmount}
              onChange={(e) => setCreditAmount(e.target.value)}
              type="number"
              fullWidth
              sx={{ mb: 2 }}
            />
          )}
          <FormControlLabel
            control={<Switch checked={override} onChange={() => setOverride(!override)} />}
            label="Override"
          />
          {override && (
            <TextField
              label="Override Count"
              value={overrideCount}
              onChange={(e) => setOverrideCount(e.target.value)}
              type="number"
              fullWidth
              sx={{ mb: 2 }}
            />
          )}
          <Button variant="contained" onClick={handleSave}>Save</Button>

          <Box mt={2}>
            <h3>Saved Details:</h3>
            {savedDetails.map((detail, index) => (
              <div key={index}>
                <p>Price Rule: {detail.priceRule}</p>
                <p>Payment Term: {detail.paymentTerm}</p>
                <p>Credit Amount: {detail.creditAmount}</p>
                <p>Override Count: {detail.overrideCount}</p>
                <hr />
              </div>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CustomerList;
