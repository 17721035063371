import { Box, Button, TextField, useTheme, Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Switch } from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import Header from "../../components/Header";

const CreateLocation = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [locations, setLocations] = useState(dummyData); // Initialize with some dummy data

  const handleFormSubmit = (values, { resetForm }) => {
    // Add new location details to the table
    setLocations([...locations, { ...values, id: locations.length + 1, enabled: true }]);
    resetForm(); // Reset the form fields after submission
  };

  const handleToggle = (id) => {
    // Toggle the enable/disable status of the location
    setLocations(locations.map(location => 
      location.id === id ? { ...location, enabled: !location.enabled } : location
    ));
  };

  return (
    <Box m="20px">
      <Header title="Create Location" subtitle="Create a New Location and assign it to Route" />
      <Card>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={locationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* Form fields for Location Code, Pincode, and Location Name */}
                <Box display="flex" justifyContent="space-between">
                  <TextField
                    fullWidth
                    id="locationCode"
                    label="Location Code"
                    value={values.locationCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.locationCode && Boolean(errors.locationCode)}
                    helperText={touched.locationCode && errors.locationCode}
                    margin="normal"
                    sx={{ mr: 2 }}
                  />
                  <TextField
                    fullWidth
                    id="pincode"
                    label="Pincode"
                    value={values.pincode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.pincode && Boolean(errors.pincode)}
                    helperText={touched.pincode && errors.pincode}
                    margin="normal"
                    sx={{ ml: 2 }}
                  />
                </Box>
                <TextField
                  fullWidth
                  id="location"
                  label="Location Name"
                  value={values.location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.location && Boolean(errors.location)}
                  helperText={touched.location && errors.location}
                  margin="normal"
                  sx={{ mt: 2 }}
                />

                {/* Submit Button */}
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    mt: 2,
                  }}
                >
                  Add Location
                </Button>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>

      {/* Table to display locations */}
      <Box mt="20px" style={{ height: "300px", overflow: "auto" }}>
        <Table style={{ backgroundColor: "white" }}>
          <TableHead>
            <TableRow>
              <TableCell>Sl.No</TableCell>
              <TableCell>Location Code</TableCell>
              <TableCell>Pincode</TableCell>
              <TableCell>Location Name</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locations.map((location) => (
              <TableRow key={location.id}>
                <TableCell>{location.id}</TableCell>
                <TableCell>{location.locationCode}</TableCell>
                <TableCell>{location.pincode}</TableCell>
                <TableCell>{location.location}</TableCell>
                <TableCell>
                  <Switch
                    checked={location.enabled}
                    onChange={() => handleToggle(location.id)}
                    color="primary"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

// Validation schema for the form fields
const locationSchema = yup.object().shape({
  locationCode: yup.string().required("Location code is required"),
  pincode: yup.string().required("Pincode is required"),
  location: yup.string().required("Location name is required"),
});

// Initial form values
const initialValues = {
  locationCode: "",
  pincode: "",
  location: "",
};

// Dummy data to populate the table initially
const dummyData = [
  { id: 1, locationCode: "LOC001", pincode: "575012", location: "MG Road", enabled: true },
  { id: 2, locationCode: "LOC002", pincode: "575011", location: "Connaught Place", enabled: true },
  { id: 3, locationCode: "LOC003", pincode: "575001", location: "Hampankatta", enabled: true },
  { id: 4, locationCode: "LOC004", pincode: "575002", location: "Kankanady", enabled: true },
  { id: 5, locationCode: "LOC005", pincode: "575003", location: "Falnir", enabled: true },
  { id: 6, locationCode: "LOC006", pincode: "575004", location: "Kadri", enabled: true },
  { id: 7, locationCode: "LOC007", pincode: "575005", location: "Kodialbail", enabled: true },
  { id: 8, locationCode: "LOC008", pincode: "575006", location: "Balmatta", enabled: true },
  { id: 9, locationCode: "LOC009", pincode: "575007", location: "Kankanady", enabled: true },
  { id: 10, locationCode: "LOC010", pincode: "575008", location: "Kulshekar", enabled: true },
  { id: 11, locationCode: "LOC011", pincode: "575009", location: "Surathkal", enabled: true },
  { id: 12, locationCode: "LOC012", pincode: "575010", location: "Panambur", enabled: true },
];

export default CreateLocation;
